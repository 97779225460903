require('./bootstrap');

import Vue from 'vue'

Vue.component('rates-widget',
    require('./components/Rates/Widget.vue').default);

const app = new Vue({
    el : '#app'
});
